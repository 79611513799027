import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';

const Table = styled.table`
  border: 1px solid black;
  width: 100%;
  font-size: 1rem;
  border-collapse: collapse;
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  table-layout: fixed;

  th {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    font-weight: 500;
  }
  tbody {
    display:block;
    width: 100%;
    overflow: auto;
    height: 380px;
  }

  thead tr {
    display: block;
  }

  th,
  td {
    padding: 0.75rem;
    font-size: 1.2rem;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    width: 400px;
  }

  tr:nth-child(odd) {
    background-color: #ffffff;
  }

  tr:nth-child(even) {
    background-color: rgb(241, 241, 241);
  }

  th:last-child,
  td:nth-child(2),
  th:nth-child(2),
  td:last-child {
    text-align: center;
    width: 220px;
  }

  td:nth-child(2),
  th:nth-child(2) {
    text-align: left;
  }

  .lightLink {
    color: #05d6ae;
  }

  .darkLink {
    color: #c21010;
  }
`;

const Calendar = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEvents(sort: { fields: date, order: DESC }) {
        edges {
          node {
            eventName
            date
            location
            link
          }
        }
      }
    }
  `);

  return (
    <div>
    <Table>
    <thead>
      <tr>
        <th>Event Name</th>
        <th>Date</th>
        <th>Location</th>
        <th>Register/Results</th>
      </tr>
    </thead>
    <tbody>
      {data.allContentfulEvents.edges.map(({ node }) => {
        const isUpComing = moment(
          moment(node.date).format('YYYY-MM-DD')
        ).isAfter(moment().format('YYYY-MM-DD'));

        return (
          <tr key={`${node.eventName}-${node.date}`}>
            <td>{node.eventName}</td>
            <td>{moment(node.date).format('MMM Do YYYY')}</td>
            <td>{node.location}</td>
            <td>
              <a
                className={isUpComing ? 'lightLink' : 'darkLink'}
                href={node.link}
                target="_blank"
                rel="noreferrer"
              >
                {isUpComing ? 'Register Now' : 'Results'}
              </a>
            </td>
          </tr>
        );
      })}
    </tbody>
    </Table>
    </div>
  );
};

export default Calendar;
