import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import {
  AiFillWarning,
  AiOutlineUsergroupAdd,
  AiOutlineTrophy,
} from 'react-icons/ai';
import { RiTeamFill } from 'react-icons/ri';
import { FaAward } from 'react-icons/fa';
import { GiPokerHand } from 'react-icons/gi';

import Header from '../components/Header';

import {
  SectionComponent,
  HeadlineComponent,
  ParagraphComponent,
  IconComponent,
  ButtonComponent,
} from '../styles/StyledComponents';
import Layout from '../layout/Layout';
import SEO from '../utils/seo';
import Calendar from '../components/Calendar';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ mg }) => mg || '0.5rem'};
  padding: ${({ pd }) => pd || '0'};
  background-color: ${({ theme, dark }) =>
    dark ? theme.colors.primary : 'white'};
  color: white;

  @media screen and (max-width: 768px) {
    margin: 0 1rem;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  flex: 1;
  margin: ${({ mg }) => mg || 0};
  padding: ${({ pd }) => pd || 0};
  background-color: ${({ theme, light }) =>
    light ? 'white' : theme.colors.primary};
  svg {
    color: #6b6b6b !important;
    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    svg {
      color: #dacb86 !important;
    }

    button {
      box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.25);
      background-color: #02c29d;
    }
  }
`;

export const Divider = styled.div`
  height: 160px;
  margin: auto 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.green};

  @media screen and (max-width: 768px) {
    height: 0px;
    width: 160px;
   margin: 0 auto;
  }
`;

export const DividerLandscape = styled.div`
  width: 260px;
  margin: 0.25rem auto;
  border: 1px solid ${({ theme }) => theme.colors.green};
`;

export const LightParagraph = styled.p`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: ${({ pd }) => pd || 0};
  text-align: ${({ alignment }) => alignment || 'center'};
  font-size: 1.2rem;
  font-weight: 300;
`;

export const PartnersContainer = styled.div`
  margin: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    & > div {
      margin-bottom: 1.5rem;
    }
  }
  
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "homepage.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service1: file(relativePath: { eq: "home-services-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service2: file(relativePath: { eq: "home-services-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service3: file(relativePath: { eq: "home-services-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo1: file(relativePath: { eq: "logo-southern.jpg" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo2: file(relativePath: { eq: "logo-apt.jpg" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo3: file(relativePath: { eq: "logo-check.jpg" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="TeamPoker®"
        link="https://teampoker.com"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <Header
        image={data.background.childImageSharp.fluid}
        text="WE MAKE POKER A TEAM SPORT™"
        hasButton
        buttonText="Start Now"
      />
      <SectionComponent>
        <HeadlineComponent>We&apos;re Team Poker®</HeadlineComponent>
        <ParagraphComponent>
          Become a Team Poker® Partner and join the Poker Sports Model where
          both Individuals and Teams compete for cash, glory and fame!
        </ParagraphComponent>
        <Container>
          <Card mg="1rem">
        <Link to="/services#online-poker">
            <Img
              imgStyle={{
                width: '100%',
                objectFit: 'fill',
              }}
              fluid={data.service1.childImageSharp.fluid}
            />
            <LightParagraph alignment="left" pd="0.75rem">
              Online Poker Room
            </LightParagraph>
        </Link>
          </Card>
          <Card mg="1rem">
          <Link to="/services#live-poker">
            <Img
              imgStyle={{
                width: '100%',
                objectFit: 'fill',
              }}
              fluid={data.service2.childImageSharp.fluid}
            />
            <LightParagraph alignment="left" pd="0.75rem">
              Live Poker Software
            </LightParagraph>
            </Link>
          </Card>
          <Card mg="1rem">
          <Link to="/services#licenses">
            <Img
              imgStyle={{
                width: '100%',
                objectFit: 'fill',
              }}
              fluid={data.service3.childImageSharp.fluid}
            />
            <LightParagraph alignment="left" pd="0.75rem">
              Championship Licenses
            </LightParagraph>
            </Link>
          </Card>
        </Container>
      </SectionComponent>
      <SectionComponent>
        <HeadlineComponent>Team Poker® Patented System</HeadlineComponent>
        <ParagraphComponent>
          Team Poker® is “The Patented Card Game Process” for the Tournament
          Poker Team Sports Model.
        </ParagraphComponent>
        <Container dark mg="1.5rem" pd="1rem 0">
          <Card pd="2rem">
          <Link to="/about#scoring">
            <HeadlineComponent color="white">SCORING</HeadlineComponent>
            <IconComponent>
              <AiOutlineTrophy />
            </IconComponent>
            <LightParagraph pd="0 2.5rem">
              The patented system allows both Individuals and Teams to compete
              in poker tournaments.
            </LightParagraph>
            </Link>
          </Card>
          <Divider />
          <Card pd="2rem">
          <Link to="/about#handicapping">
            <HeadlineComponent color="white">HANDICAPPING</HeadlineComponent>
            <IconComponent>
              <AiOutlineUsergroupAdd />
            </IconComponent>
            <LightParagraph pd="0 2.5rem">
              Any Size Team is possible in the same tournament with the
              TeamPoker® Handicapping System.
            </LightParagraph>
            </Link>
          </Card>
          <Divider />
          <Card pd="2rem">
          <Link to="/about#seating">
            <HeadlineComponent color="white">SEATING</HeadlineComponent>
            <IconComponent>
              <AiFillWarning />
            </IconComponent>
            <LightParagraph pd="0 2.5rem">
              Our Poker Sports Model includes options for Collusion Free Seating
              Protocol.
            </LightParagraph>
            </Link>
          </Card>
        </Container>
      </SectionComponent>
      <SectionComponent>
        <HeadlineComponent>Team Poker® CasinoWare</HeadlineComponent>
        <ParagraphComponent>
          The Most Professional Poker Room Software on the Market Now with Team
          Poker®
        </ParagraphComponent>
        <Container>
          <Card mg="1rem" pd="2rem">
            <HeadlineComponent color="white">FREE CASINOWARE</HeadlineComponent>
            <IconComponent>
              <GiPokerHand />
            </IconComponent>
            <DividerLandscape />
            <LightParagraph pd="2rem 0 1rem 0">100 Players</LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">3 Tables</LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">
              1x Tournament Floor
            </LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">
              1x Tournament Control
            </LightParagraph>
            <LightParagraph pd="1rem 0 2rem 0">Limited Support</LightParagraph>
            <a href="https://casinoware.com/features/teampoker" target="_blank" rel="noreferrer">
            <ButtonComponent>Download Now</ButtonComponent>
            </a>
          </Card>
          <Card mg="1rem" pd="2rem">
            <HeadlineComponent color="white">PRO CASINOWARE</HeadlineComponent>
            <IconComponent>
              <RiTeamFill />
            </IconComponent>
            <DividerLandscape />
            <LightParagraph pd="2rem 0 1rem 0">
              Unlimited Players
            </LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">Unlimited Tables</LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">
              1x Tournament Floor
            </LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">
              1x Tournament Control
            </LightParagraph>
            <LightParagraph pd="1rem 0 2rem 0">Live Support</LightParagraph>
            <a href="https://casinoware.com/features/teampoker" target="_blank" rel="noreferrer">
            <ButtonComponent>Purchase Now</ButtonComponent>
            </a>
          </Card>
          <Card mg="1rem" pd="2rem">
            <HeadlineComponent color="white">LICENSES</HeadlineComponent>
            <IconComponent>
              <FaAward />
            </IconComponent>
            <DividerLandscape />
            <LightParagraph pd="2rem 0 1rem 0">
              Live Championships
            </LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">
              Online Championships
            </LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">
              Video Productions
            </LightParagraph>
            <LightParagraph pd="1rem 0 1rem 0">Merchandising</LightParagraph>
            <LightParagraph pd="1rem 0 2rem 0">
              eSports Licensing
            </LightParagraph>
            <Link to='/contact'>
            <ButtonComponent>Contact us</ButtonComponent>
            </Link>
          </Card>
        </Container>
      </SectionComponent>
      <SectionComponent>
        <HeadlineComponent>Up Coming Events</HeadlineComponent>
        <ParagraphComponent>
          Find Team Poker® upcoming events near you
        </ParagraphComponent>
        <Container mg="1.5rem">
          <Calendar />
        </Container>
      </SectionComponent>
      <SectionComponent>
        <HeadlineComponent>Trusted by</HeadlineComponent>
        <ParagraphComponent>
          Our Patented System powers some of the most prestigious casinos in the
          world.
        </ParagraphComponent>
        <PartnersContainer>
          <Img
            style={{ display: 'block' }}
            fixed={data.logo1.childImageSharp.fixed}
          />
          <Img
            style={{ display: 'block' }}
            fixed={data.logo2.childImageSharp.fixed}
          />
          <Img
            style={{ display: 'block' }}
            fixed={data.logo3.childImageSharp.fixed}
          />
        </PartnersContainer>
      </SectionComponent>
    </Layout>
  );
};

export default IndexPage;
